var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
$(function () {
  var actions = $("tbody .col-actions");
  function waitForOutsideClick(el) {
    $("body").on("click.toggle", function (e) {
      if (el.contains(e.currentTarget) === false) {
        actions.removeClass("active");
        $("body").off("click.toggle");
      }
    });
  }

  // Make actions focusable
  actions.attr("tabindex", 0).attr("role", "button");
  actions.on("click", function () {
    $(this || _global).addClass("active");
    waitForOutsideClick(this || _global);
  });
  actions.on("keydown", function (e) {
    var space = 32;
    var enter = 13;
    var escape = 27;
    if (e.keyCode == space || e.keyCode == enter) {
      e.preventDefault();
      $(this || _global).addClass("active");
      waitForOutsideClick(this || _global);
    } else if (e.keyCode == escape) {
      e.preventDefault();
      $(this || _global).removeClass("active");
    }
  });
});
export default {};